import React, { useState, useContext, useRef, useEffect } from "react"
import Layout from "../components/layout"
import { Alert, Form, Row, Col, Container, Button } from "react-bootstrap"
import api from "../utils/api"
import LoadingOverlay from "react-loading-overlay"
import styled from "styled-components"
import Select from "react-select"
import { NavbarContext } from "../context/navbar"
import { Link } from "gatsby"
import Modal from "../components/home/modal"

function UploadUserFile({ userId, setUserLicense }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [selectedFile, setSelectedFile] = useState({})
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [fileUrl, setFileUrl] = useState("")

  const handleChangeFile = event => {
    setSelectedFile(event.target.files[0])
    setFileUrl(URL.createObjectURL(event.target.files[0]))
    setIsFilePicked(true)
  }

  const uploadFile = async () => {
    try {
      const formData = new FormData()

      formData.append("file", selectedFile)

      setLoading(true)
      const result = await api().patch(`/users/${userId}/license`, formData)
      setLoading(false)
      setSuccess("Uploaded file")
      setSelectedFile({})
      setIsFilePicked(false)
      setFileUrl("")

      localStorage.setItem("user", JSON.stringify(result.data))
      setUserLicense(result.data.license)
    } catch (err) {
      const { message } = err.response.data
      setLoading(false)
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          <Form.Label>
            <h5 className="text-muted pt-2">
              Upload individual practicing license from the board
            </h5>
            <Alert variant="danger">
              Verify you are registered to practice
            </Alert>
          </Form.Label>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                onChange={e => {
                  handleChangeFile(e)
                }}
              />
            </Form.Group>

            {isFilePicked && (
              <RequestCallBackBtn
                onClick={e => {
                  uploadFile()
                }}
              >
                Upload
              </RequestCallBackBtn>
            )}
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}

function UploadPharmacyFile({ userId, setPremiseLicense }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [selectedFile, setSelectedFile] = useState({})
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [fileUrl, setFileUrl] = useState("")

  const handleChangeFile = event => {
    setSelectedFile(event.target.files[0])
    setFileUrl(URL.createObjectURL(event.target.files[0]))
    setIsFilePicked(true)
  }

  const uploadFile = async () => {
    try {
      const formData = new FormData()

      formData.append("file", selectedFile)

      setLoading(true)
      const result = await api().patch(`/users/${userId}/pharmacy`, formData)
      setLoading(false)
      setSuccess("Uploaded file")
      setSelectedFile({})
      setIsFilePicked(false)
      setFileUrl("")

      localStorage.setItem("user", JSON.stringify(result.data))
      setPremiseLicense(result.data?.placeOfWork?.license)
    } catch (err) {
      const { message } = err.response.data
      setLoading(false)
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          <Form.Label>
            <h5 className="text-muted pt-2">Upload premise PPB license</h5>

            <Alert variant="danger">
              Verify you are a registered pharmacy
            </Alert>
          </Form.Label>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                onChange={e => {
                  handleChangeFile(e)
                }}
              />
            </Form.Group>

            {isFilePicked && (
              <RequestCallBackBtn
                onClick={e => {
                  uploadFile()
                }}
              >
                Upload
              </RequestCallBackBtn>
            )}
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}

function UploadPharmacyDocs({ userId, setUploadDocs }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [selectedFile, setSelectedFile] = useState({})
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [fileUrl, setFileUrl] = useState("")

  const handleChangeFile = event => {
    setSelectedFile(event.target.files[0])
    setFileUrl(URL.createObjectURL(event.target.files[0]))
    setIsFilePicked(true)
  }

  const uploadFile = async () => {
    try {
      const formData = new FormData()

      formData.append("file", selectedFile)

      setLoading(true)
      const result = await api().patch(`/users/${userId}/pharmacydocs`, formData)
      setLoading(false)
      setSuccess("Uploaded file")
      setSelectedFile({})
      setIsFilePicked(false)
      setFileUrl("")

      localStorage.setItem("user", JSON.stringify(result.data))
      setUploadDocs(result.data?.placeOfWork?.companydocs)
    } catch (err) {
      const { message } = err.response.data
      setLoading(false)
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                onChange={e => {
                  handleChangeFile(e)
                }}
              />
            </Form.Group>

            {isFilePicked && (
              <RequestCallBackBtn
                onClick={e => {
                  uploadFile()
                }}
              >
                Upload
              </RequestCallBackBtn>
            )}
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}

function UploadPharmacyPassport({ userId, setUploadDocs }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [selectedFile, setSelectedFile] = useState({})
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [fileUrl, setFileUrl] = useState("")

  const handleChangeFile = event => {
    setSelectedFile(event.target.files[0])
    setFileUrl(URL.createObjectURL(event.target.files[0]))
    setIsFilePicked(true)
  }

  const uploadFile = async () => {
    try {
      const formData = new FormData()

      formData.append("file", selectedFile)

      setLoading(true)
      const result = await api().patch(`/users/${userId}/pharmacypassport`, formData)
      setLoading(false)
      setSuccess("Uploaded file")
      setSelectedFile({})
      setIsFilePicked(false)
      setFileUrl("")

      localStorage.setItem("user", JSON.stringify(result.data))
      setUploadDocs(result.data?.placeOfWork?.passport)
    } catch (err) {
      const { message } = err.response.data
      setLoading(false)
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                onChange={e => {
                  handleChangeFile(e)
                }}
              />
            </Form.Group>

            {isFilePicked && (
              <RequestCallBackBtn
                onClick={e => {
                  uploadFile()
                }}
              >
                Upload
              </RequestCallBackBtn>
            )}
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}

function UploadPharmacyPincert({ userId, setUploadDocs }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [selectedFile, setSelectedFile] = useState({})
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [fileUrl, setFileUrl] = useState("")

  const handleChangeFile = event => {
    setSelectedFile(event.target.files[0])
    setFileUrl(URL.createObjectURL(event.target.files[0]))
    setIsFilePicked(true)
  }

  const uploadFile = async () => {
    try {
      const formData = new FormData()

      formData.append("file", selectedFile)

      setLoading(true)
      const result = await api().patch(`/users/${userId}/pharmacypincert`, formData)
      setLoading(false)
      setSuccess("Uploaded file")
      setSelectedFile({})
      setIsFilePicked(false)
      setFileUrl("")

      localStorage.setItem("user", JSON.stringify(result.data))
      setUploadDocs(result.data?.placeOfWork?.pincert)
    } catch (err) {
      const { message } = err.response.data
      setLoading(false)
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                onChange={e => {
                  handleChangeFile(e)
                }}
              />
            </Form.Group>

            {isFilePicked && (
              <RequestCallBackBtn
                onClick={e => {
                  uploadFile()
                }}
              >
                Upload
              </RequestCallBackBtn>
            )}
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}


function UploadPharmacyCr12({ userId, setUploadDocs }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [selectedFile, setSelectedFile] = useState({})
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [fileUrl, setFileUrl] = useState("")

  const handleChangeFile = event => {
    setSelectedFile(event.target.files[0])
    setFileUrl(URL.createObjectURL(event.target.files[0]))
    setIsFilePicked(true)
  }

  const uploadFile = async () => {
    try {
      const formData = new FormData()

      formData.append("file", selectedFile)

      setLoading(true)
      const result = await api().patch(`/users/${userId}/pharmacycr12`, formData)
      setLoading(false)
      setSuccess("Uploaded file")
      setSelectedFile({})
      setIsFilePicked(false)
      setFileUrl("")

      localStorage.setItem("user", JSON.stringify(result.data))
      setUploadDocs(result.data?.placeOfWork?.cr12)
    } catch (err) {
      const { message } = err.response.data
      setLoading(false)
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                onChange={e => {
                  handleChangeFile(e)
                }}
              />
            </Form.Group>

            {isFilePicked && (
              <RequestCallBackBtn
                onClick={e => {
                  uploadFile()
                }}
              >
                Upload
              </RequestCallBackBtn>
            )}
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}

export default function Profile() {
  const isBrowser = typeof window !== "undefined"
  const user = isBrowser ? JSON.parse(window.localStorage.getItem("user")) : {}

  const navContext = useContext(NavbarContext)

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [info, setInfo] = useState("Fill in place of work to order")
  const [isLoading, setIsLoading] = useState(false)

  const [firstName, setFirstName] = useState(user?.firstName || "")
  const [lastName, setLastName] = useState(user?.lastName || "")
  const [email, setEmail] = useState(user?.email || "")
  const [profession, setProfession] = useState(user?.profession || "")
  const [registrationNo, setRegistrationNo] = useState(
    user?.registrationNo || ""
  )
  const [phone, setPhone] = useState(
    user?.Phone || ""
  )

  const [placeOfWork, setPlaceOfWork] = useState(user?.placeOfWork || {})

  const [countryOfWork, setCountryOfWork] = useState({
    value: placeOfWork?.countryOfWork,
    label: placeOfWork?.countryOfWork,
  })
  const [countyOfWork, setCountyOfWork] = useState({
    value: placeOfWork?.countyLocation,
    label: placeOfWork?.countyLocation,
  })
  const [nameOfWork, setNameOfWork] = useState(placeOfWork?.name)
  const [streetNameOfWork, setStreetNameOfWork] = useState(placeOfWork?.countyStreet)

  const countries = [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua And Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia And Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, Democratic Republic",
    "Cook Islands",
    "Costa Rica",
    "Cote D'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island & Mcdonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic Of",
    "Iraq",
    "Ireland",
    "Isle Of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States Of",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory, Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Barthelemy",
    "Saint Helena",
    "Saint Kitts And Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre And Miquelon",
    "Saint Vincent And Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome And Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia And Sandwich Isl.",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard And Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad And Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks And Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis And Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ]

  const counties = [
    "Mombasa",
    "Kwale",
    "Kilifi",
    "Tana River",
    "Lamu",
    "Taita/Taveta",
    "Garissa",
    "Wajir",
    "Mandera",
    "Marsabit",
    "Isiolo",
    "Meru",
    "Tharaka-Nithi",
    "Embu",
    "Kitui",
    "Machakos",
    "Makueni",
    "Nyandarua",
    "Nyeri",
    "Kirinyaga",
    "Murang'a",
    "Kiambu",
    "Turkana",
    "West Pokot",
    "Samburu",
    "Trans Nzoia",
    "Uasin Gishu",
    "Elgeyo/Marakwet",
    "Nandi",
    "Baringo",
    "Laikipia",
    "Nakuru",
    "Narok",
    "Kajiado",
    "Kericho",
    "Bomet",
    "Kakamega",
    "Vihiga",
    "Bungoma",
    "Busia",
    "Siaya",
    "Kisumu",
    "Homa Bay",
    "Migori",
    "Kisii",
    "Nyamira",
    "Nairobi City",
  ]

  const [filteredCountries, setFilteredCountries] = useState(countries.sort())
  const [filteredCounties, setFilteredCounties] = useState(counties.sort())

  const countriesArray = filteredCountries.map((country, index) => {
    const data = {
      value: country,
      label: country,
    }
    return data
  })

  const selectCountry = selectedOption => {
    setCountryOfWork(selectedOption)
    setCountyOfWork("")
  }

  const countiesArray = filteredCounties.map((county, index) => {
    const data = {
      value: county,
      label: county,
    }
    return data
  })

  const selectCounty = selectedOption => {
    setCountyOfWork(selectedOption)
  }

  const updateUser = async e => {
    e.preventDefault()

    setIsLoading(true)
    setError("")
    setSuccess("")
    const data = {
      firstName,
      lastName,
      email,
      profession,
      registrationNo,
      phone,
      placeOfWork: {
        countryOfWork: countryOfWork.value,
        countyLocation: countyOfWork.value,
        name: nameOfWork,
        countyStreet: streetNameOfWork
      },
    }

    if (data.placeOfWork.countyLocation === undefined) {
      delete data.placeOfWork.countyLocation
    }
    if (
      !firstName ||
      !lastName ||
      !profession ||
      !email ||
      !countryOfWork.value ||
      !nameOfWork ||
      (countryOfWork.value == "Kenya" && !countyOfWork.value)
    ) {
      setIsLoading(false)
      return setError("Missing required(*) fields.")
    }
    if (profession != "Med Reps or Sales" && !registrationNo) {
      setIsLoading(false)
      return setError("Registration number is required.")
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setIsLoading(false)
      return setError("Invalid email.")
    } else {
      try {
        const result = await api().patch(`/users/${user.id}`, { ...data })

        localStorage.setItem("user", JSON.stringify(result.data))
        /* navContext.setUser(JSON.stringify(result.data)) */
        setSuccess("Updated")
        setIsLoading(false)
      } catch (error) {
        const message = error.response.data.message
        setIsLoading(false)
        setError(message)
      }
    }
  }

  const [userLicense, setUserLicense] = useState(user?.license || "")
  const [premiseLicense, setPremiseLicense] = useState(
    user?.placeOfWork?.license || ""
  )

  const [companydocs, setCompanydocs] = useState(
    user?.placeOfWork?.companydocs || ""
  )
  const [cr12, setCr12] = useState(
    user?.placeOfWork?.cr12 || ""
  )
  const [pincert, setPincert] = useState(
    user?.placeOfWork?.pincert || ""
  )
  const [passport, setPassport] = useState(
    user?.placeOfWork?.passport || ""
  )

  const licenseRef = useRef(null)
  const scrollToElement = () => licenseRef.current.scrollIntoView()

  useEffect(() => {
    if (!premiseLicense) {
      scrollToElement({ behavior: "smooth", block: "start" })
    }
  }, [])

  return (
    <Layout>
      <Modal />
      <div style={{ margin: "40px" }}>
        <div style={{ textAlign: "center" }}>
          <h2>Your Profile</h2>
        </div>

        {!user?.placeOfWork?.name ? (
          <Alert variant="info">Fill in place of work to order</Alert>
        ) : (
          ""
        )}
        {error ? <Alert variant="danger">{error}</Alert> : ""}
        {success ? <Alert variant="success">{success}</Alert> : ""}
        <div>
          <Form>
            <Row>
              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                <Form.Label>First Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </Col>

              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                <Form.Label>Last Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </Col>

              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Col>

              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                <Form.Label>Profession*</Form.Label>

                <Form.Control
                  as="select"
                  value={profession}
                  onChange={e => setProfession(e.target.value)}
                >
                  <option>Doctor</option>
                  <option>Nurse</option>
                  <option>Pharmacist</option>
                  <option>Pharmaceutical Technologist</option>
                  <option>Clinical Officer</option>
                  <option>Med Reps or Sales</option>
                </Form.Control>
              </Col>

              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                <Form.Label>Registration No</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Registration no"
                  value={registrationNo}
                  onChange={e => setRegistrationNo(e.target.value)}
                />
              </Col>

              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone no"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </Col>
            </Row>

            <h5>Place of Work</h5>

            <Row>
              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                <Form.Label>Country*</Form.Label>

                <Select
                  value={countryOfWork}
                  onChange={selectCountry}
                  options={countriesArray}
                />
              </Col>
              {countryOfWork.value === "Kenya" && (
                <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                  <Form.Label>County*</Form.Label>

                  <Select
                    value={countyOfWork}
                    onChange={selectCounty}
                    options={countiesArray}
                  />
                </Col>
              )}
              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                <Form.Label>Street Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Street Name of workplace"
                  value={streetNameOfWork}
                  onChange={e => setStreetNameOfWork(e.target.value)}
                />
              </Col>
              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                <Form.Label>Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name of workplace"
                  value={nameOfWork}
                  onChange={e => setNameOfWork(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ justifyContent: "flex-end" }} ref={licenseRef}>
              <Col xs={12} sm={3} className="md:pt-10">
                <Button onClick={e => updateUser(e)}>Update</Button>
              </Col>
            </Row>

            <br />

            <Row>
              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                {userLicense ? (
                  <>
                    <h5>Individual PPB license</h5>
                    {/* <img src={userLicense} /> */}
                    <Link
                      to={userLicense}
                      target='_blank'
                    >
                      <Button
                        style={{
                          backgroundColor: "#71c4de",
                          borderColor: "grey",
                          color: "white",
                        }}
                      >
                        View
                      </Button>
                    </Link>
                    <br />
                    <Button
                      onClick={() => setUserLicense("")}
                      style={{
                        backgroundColor: "grey",
                        borderColor: "grey",
                        color: "white",
                        marginTop: "10px"
                      }}
                    >
                      Remove
                    </Button>
                  </>
                ) : (
                  <>
                    <h5>Verify user </h5>
                    <UploadUserFile
                      userId={user?.id || ""}
                      setUserLicense={setUserLicense}
                    />
                  </>
                )}
              </Col>

              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                {premiseLicense ? (
                  <>
                    <h5>Premise PPB license</h5>
                    {/* <img src={premiseLicense} /> */}
                    <Link
                      to={premiseLicense}
                      target='_blank'
                    >
                      <Button
                        style={{
                          backgroundColor: "#71c4de",
                          borderColor: "grey",
                          color: "white",
                        }}
                      >
                        View
                      </Button>
                    </Link>
                    <br />
                    <Button
                      onClick={() => setPremiseLicense("")}
                      style={{
                        backgroundColor: "grey",
                        borderColor: "grey",
                        color: "white",
                        marginTop: "10px"
                      }}
                    >
                      Remove
                    </Button>
                  </>
                ) : (
                  <>
                    <h5>Verify premise </h5>
                    <UploadPharmacyFile
                      userId={user?.id || ""}
                      setPremiseLicense={setPremiseLicense}
                    />
                  </>
                )}
              </Col>
            </Row>

            <br />

            <br />

            <h3>For Account Opening (Optional)</h3>
            <p>Required documents to open an account with various suppliers and be approved for Credit</p>

            <Row>
              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                {companydocs ? (
                  <>
                    <h5>copy of company certificate of incorporation/partnership deed/business registration certificate</h5>
                    {/* <img src={companydocs} /> */}
                    <Link
                      to={companydocs}
                      target='_blank'
                    >
                      <Button
                        style={{
                          backgroundColor: "#71c4de",
                          borderColor: "grey",
                          color: "white",
                        }}
                      >
                        View
                      </Button>
                    </Link>
                    <br />
                    <Button
                      onClick={() => setCompanydocs("")}
                      style={{
                        backgroundColor: "grey",
                        borderColor: "grey",
                        color: "white",
                        marginTop: "10px"
                      }}
                    >
                      Remove
                    </Button>
                  </>
                ) : (
                  <>
                    <h5>Copy of company certificate of incorporation/partnership deed/business registration certificate </h5>
                    <UploadPharmacyDocs
                      userId={user?.id || ""}
                      setUploadDocs={setCompanydocs}
                    />
                  </>
                )}
              </Col>
              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                {cr12 ? (
                  <>
                    <h5>copy of CR12</h5>
                    {/* <img src={cr12} /> */}
                    <Link
                      to={cr12}
                      target='_blank'
                    >
                      <Button
                        style={{
                          backgroundColor: "#71c4de",
                          borderColor: "grey",
                          color: "white",
                        }}
                      >
                        View
                      </Button>
                    </Link>
                    <br />
                    <Button
                      onClick={() => setCr12("")}
                      style={{
                        backgroundColor: "grey",
                        borderColor: "grey",
                        color: "white",
                        marginTop: "10px"
                      }}
                    >
                      Remove
                    </Button>
                  </>
                ) : (
                  <>
                    <h5>Copy of CR12 </h5>
                    <UploadPharmacyCr12
                      userId={user?.id || ""}
                      setUploadDocs={setCr12}
                    />
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                {pincert ? (
                  <>
                    <h5>copy of kra pin certificate</h5>
                    {/* <img src={pincert} /> */}
                    <Link
                      to={pincert}
                      target='_blank'
                    >
                      <Button
                        style={{
                          backgroundColor: "#71c4de",
                          borderColor: "grey",
                          color: "white",
                        }}
                      >
                        View
                      </Button>
                    </Link>
                    <br />
                    <Button
                      onClick={() => setPincert("")}
                      style={{
                        backgroundColor: "grey",
                        borderColor: "grey",
                        color: "white",
                        marginTop: "10px"
                      }}
                    >
                      Remove
                    </Button>
                  </>
                ) : (
                  <>
                    <h5>Copy of kra pin certificate </h5>
                    <UploadPharmacyPincert
                      userId={user?.id || ""}
                      setUploadDocs={setPincert}
                    />
                  </>
                )}
              </Col>
              <Col xs={12} sm={6} style={{ margin: "10px 0px" }}>
                {passport ? (
                  <>
                    <h5>copy of the passport or National Identity cards for the directors</h5>
                    {/* <img src={passport} /> */}
                    <Link
                      to={passport}
                      target='_blank'
                    >
                      <Button
                        style={{
                          backgroundColor: "#71c4de",
                          borderColor: "grey",
                          color: "white",
                          marginTop: "10px"
                        }}
                      >
                        View
                      </Button>
                    </Link>
                    <br />
                    <Button
                      onClick={() => setPassport("")}
                      style={{
                        backgroundColor: "grey",
                        borderColor: "grey",
                        color: "white",
                      }}
                    >
                      Remove
                    </Button>
                  </>
                ) : (
                  <>
                    <h5>Copy of the passport or National Identity cards for the directors </h5>
                    <UploadPharmacyPassport
                      userId={user?.id || ""}
                      setUploadDocs={setPassport}
                    />
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Layout>
  )
}

const RequestCallBackBtn = styled.div`
  background: #03989e;
  padding: 1rem 4rem;
  margin-top: 1rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
`
